<script>
export default {
  name: 'Coder',
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  methods: {
    copyCode () {
      this.$copyText(this.code).then(message => {
        this.$message.success('Copied')
      })
    }
  }
}
</script>

<template>
  <div class="code-container">
    <div class="code-copy"><a :title="$t('user.code.copy')" @click="copyCode"><a-icon type="copy" style="font-size: 0.8rem;"></a-icon><span style="position: relative;top:0px;">Copy</span></a></div>
    <div class="code-box" v-highlight>
      <code style="display: block;">{{ code }}</code>
    </div>
  </div>
</template>

<style scoped>
  .code-container{
     position: relative;
  }

  .code-container .code-copy{
    display: none;
    position: absolute;
    right:12px;
    top:2px;
  }

  .code-container:hover .code-copy{
    display: block;
  }

  .code-box{
    margin: 0 !important;
    background-color: #2b2b2b;
    overflow: hidden;
    color: white;
    padding: 16px 4px 8px 8px;
  }

</style>
