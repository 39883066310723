<script>
import Coder from '@/components/Coder/Coder.vue'
import { UTOKEN } from '@/store/mutation-types'
import Vue from 'vue'
import { loadMessageList } from '@/api'
import moment from 'moment'
import { getI18nLanguage } from '@/locales'
export default {
  components: {
    Coder
  },
  created () {
    const uToken = Vue.ls.get(UTOKEN)
    this.code = `curl -sSL https://relaxcert.com/sh | bash -s '${uToken}'`
    if (getI18nLanguage() === 'zh-CN') {
      this.code = `curl -sSL https://relaxcert.com/sh/zh | bash -s '${uToken}'`
    }
    this.$nextTick(function () {
        this.imageHeight = this.$refs.left.clientWidth * 9 / 16
      setInterval(() => {
        this.$refs.carousel.next()
      }, 10000)
    })
    loadMessageList({ pageNo: 1, pageSize: 4 }).then(res => {
      this.messages = res.result.data
    })
  },
  data () {
    return {
       code: '',
      imageHeight: 0,
      bannerItems: [{
         img: '/images/banner/banner_1.jpg',
         title: this.$t('index.banner.title1'),
         content: this.$t('index.banner.content1')
      }, {
         img: '/images/banner/banner_2.jpg',
         title: this.$t('index.banner.title2'),
         content: this.$t('index.banner.content2')
      }, {
        img: '/images/banner/banner_3.jpg',
        title: this.$t('index.banner.title3'),
        content: this.$t('index.banner.content3')
      }, {
        img: '/images/banner/banner_4.jpg',
        title: this.$t('index.banner.title4'),
        content: this.$t('index.banner.content4')
      }],
      notifications: [],
      messages: []
    }
  },
  methods: {
    moment
  }
}
</script>

<template>
  <div>
    <a-row :gutter="12">
      <a-col :xxl="17" :xl="15" >
        <div class="ant-card" ref="left">
          <a-carousel ref="carousel" effect="fade" dot-position="right">
            <div v-for="banner in bannerItems" class="banner-item" :style="{height:imageHeight+'px'}">
              <img :src="banner.img" style="width: 100%;" :style="{height:imageHeight+'px'}"/>
              <div class="top-title">{{ banner.title }}</div>
              <div class="bottom-content">{{ banner.content }}</div>
            </div>
          </a-carousel>
        </div>
        <div style="margin-top: 12px;">
          <div class="ant-card" style="border-radius: 4px;padding: 8px;" >
            <div style="padding: 8px 0;">{{ $t('server.linux.nginx.tip') }}</div>
            <div>
              <coder :code="code"></coder>
            </div>
            <div style="padding: 8px 0;">{{ $t('server.linux.nginx.tip2') }}<a>{{ $t('server.linux.nginx.key.update') }}</a></div>
          </div>
        </div>
        <div style="margin-top: 12px;">
          <div class="ant-card" style="border-radius: 4px;padding: 8px;" >
            <div style="padding: 8px 0;">{{ $t('server.windows.iis.tip') }}</div>
            <div>
              <a :title="$t('function.come.soon')">{{ $t('server.windows.iis.32.download') }}</a>
              <a-divider type="vertical" />
              <a :title="$t('function.come.soon')">{{ $t('server.windows.iis.64.download') }}</a>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xxl="7" :xl="9">
        <div class="ant-card" style="border-radius: 4px;">
          <a-card :title="$t('user.notification.new')" style="border-radius: 4px;">
            <a-list item-layout="horizontal" :data-source="notifications" style="border-radius: 8px;">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta
                  description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                >
                  <a slot="title" href="https://www.antdv.com/">{{ item.title }}</a>
                  <a-avatar
                    slot="avatar"
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  />
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-card>
        </div>
        <div class="ant-card" style="margin-top: 16px;border-radius: 4px;">
          <a-card :title="$t('user.dynamic.message')" :bodyStyle="{ paddingTop: 0,paddingBottom:0}" style="border-radius: 4px;">
            <a-list item-layout="horizontal" :data-source="messages">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta
                  :description="item.message"
                >
                  <div slot="title" style="display:flex; "><div style="flex: 1;font-weight: bold;">{{ item.msg_title }}</div><div>{{ moment(item.create_time).format("YYYY-MM-DD HH:mm") }}</div></div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped>
  p{
    text-indent: 2em;
  }

  .ant-carousel >>> .slick-slide h3 {
    color: #fff;
  }

  .banner-item{
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  .top-title{
    position: absolute;
    top:0;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    color: white;
    font-size: 1.1rem;
    padding: 4px 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .bottom-content{
    position: absolute;
    bottom:0;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    color: white;
    padding: 4px 8px;
    font-size: 0.8rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>
